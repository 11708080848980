<template>
  <v-row>
    <!-- toolbar -->
    <v-col cols="12">
      <v-toolbar dense flat color="primary" dark rounded>
        <v-toolbar-title
          class="headline font-weight-medium"
          v-html="toolbarTitle"
        />
        <v-spacer />
        <v-text-field
          class="toolbarSearch"
          rounded
          label="Search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
          clearable
        ></v-text-field>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            depressed
            color="primary"
            @click="addNewItem"
            title="Add New Package Terms"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn depressed color="primary" to="/" title="Go Home">
            <v-icon>mdi-home-outline</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>
    <!-- table -->
    <v-col cols="12">
      <v-card outlined>
        <v-card-text class="text--primary body-2 font-weight-medium">
          <span class="green--text"
            >Terms with green rows are currently assigned to packages and live
            on public websites.</span
          >
        </v-card-text>
        <v-data-table
          :loading="loading"
          dense
          :headers="[
            { text: 'Title', value: 'title' },
            { text: 'Archived', value: 'archived' },
          ]"
          :items="items"
          :items-per-page="-1"
          @click:row="editItem"
          :search="search"
          hide-default-footer
          :item-class="itemClass"
        ></v-data-table>
      </v-card>
    </v-col>
    <!-- full screen edit/add dialog -->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <!-- dialog toolbar -->
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>
            <strong> {{ editing ? "Edit" : "Add New Item" }} </strong>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              depressed
              color="primary"
              dark
              :loading="loading"
              :disabled="loading"
              @click="dialog = false"
              title="Close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- dialog content -->
        <v-card-text class="text--primary mt-6">
          <!-- form -->
          <v-form ref="form" @submit.prevent="save">
            <v-row>
              <!-- title -->
              <v-col cols="6">
                <v-text-field
                  v-model="item.title"
                  :loading="loading"
                  :disabled="loading"
                  label="Title"
                  hide-details="auto"
                  :rules="[textRules.required]"
                ></v-text-field>
              </v-col>
              <!-- archived -->
              <v-col cols="6">
                <v-select
                  v-model="item.archived"
                  :items="[true, false]"
                  :loading="loading"
                  :disabled="loading"
                  label="Archived"
                  hide-details="auto"
                  :rules="[selectRules.boolean]"
                ></v-select>
              </v-col>
              <!-- content -->
              <v-col cols="12">
                <v-textarea
                  v-model="item.content"
                  :loading="loading"
                  :disabled="loading"
                  label="Content"
                  hide-details="auto"
                  persistent-hint
                  hint="Content must be wrapped in HTML <ul class='list-disc'><li></li></ul>"
                  rows="25"
                  :rules="[textRules.required]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!-- actions -->
        <v-card-actions class="sticky-v-card-actions">
          <v-btn
            color="error"
            text
            @click="deleteDialog = true"
            :loading="loading"
            :disabled="loading"
            v-if="editing"
            >Delete</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading"
            @click="save"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Delete Record? </v-card-title>
        <v-card-text class="text--primary">
          Please contact the IT department to delete a record.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="deleteDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- warning dialog -->
    <v-dialog v-model="warningDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Important </v-card-title>
        <v-card-text class="text--primary body-1">
          <p class="mt-5">
            If you are doing anything besides updating a set of terms, please
            contact the IT department before proceeding.
          </p>
          <p>Thank you</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="warningDialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mainApp } from "@/firebase";
import formRules from "@/mixins/formRules";
import { portalsApp } from "../firebase";
export default {
  mixins: [formRules],
  firestore: {
    items: mainApp
      .firestore()
      .collection("termsAndConditions")
      .orderBy("archived"),
  },
  data: () => ({
    loading: false,
    toolbarTitle: "Package Terms",
    search: "",
    items: [],
    editing: false,
    dialog: false,
    item: "",
    deleteDialog: false,
    warningDialog: true,
  }),
  methods: {
    itemClass(item) {
      if (!item) return "";
      // marking items red or green based on archive status
      if (item.archived) {
        return "red lighten-4 cursorPointer";
      } else {
        return "green lighten-4 cursorPointer";
      }
    },
    addNewItem() {
      this.editing = false;
      this.dialog = true;
      this.item = {
        title: "",
        archived: null,
        content: "",
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async editItem(item) {
      // fetching item again to create a "fresh" copy to edit that will not effect items in table if user cancels the edit
      if (!item) return;
      this.loading = true;
      mainApp
        .firestore()
        .collection("termsAndConditions")
        .doc(item.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            this.item = data;
            this.editing = true;
            this.dialog = true;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.loading = false;
        });
    },
    async save() {
      if (this.editing) {
        if (!this.$refs.form.validate()) return;
        // save/edit function updates both the doc in the termsAndConditions collection && all the invidual packages with those terms
        this.loading = true;

        // update doc in termsAndConditions collection
        let termsDoc = mainApp
          .firestore()
          .collection("termsAndConditions")
          .doc(this.item.id);
        let termsDocData = await termsDoc.get();
        if (termsDocData.id != this.item.id || !termsDocData.exists) return;
        await termsDoc.update(this.item);

        // update individual packages containing those terms
        let packagesWithTerms = mainApp
          .firestore()
          .collection("packages")
          .where("termsAndConditions", "==", this.item.id);
        let packagesWithTermsData = await packagesWithTerms.get();

        if (!packagesWithTermsData.docs.length) {
          this.loading = false;
          this.dialog = false;
          this.item = "";
          // success snackbar
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Item updated successfully",
            timeout: 5000,
          });
        } else {
          packagesWithTermsData = packagesWithTermsData.docs.map((i) =>
            i.data()
          );
          for (let index = 0; index < packagesWithTermsData.length; index++) {
            const element = packagesWithTermsData[index];
            let newTerms = this.item;
            await mainApp
              .firestore()
              .collection("packages")
              .doc(element.id)
              .update({ terms: newTerms });
          }

          
          // success snackbar
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Item updated successfully",
            timeout: 5000,
          });
        }


        // update individual retail packages containing those terms
        let retailPackagesWithTerms = portalsApp
          .firestore()
          .collection("retailPackages")
          .where("termsAndConditions", "==", this.item.id);
        let retailPackagesWithTermsData = await retailPackagesWithTerms.get();

        if (!retailPackagesWithTermsData.docs.length) {
          this.loading = false;
          this.dialog = false;
          this.item = "";
          // success snackbar
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Item updated successfully",
            timeout: 5000,
          });
        } else {
          retailPackagesWithTermsData = retailPackagesWithTermsData.docs.map((i) =>
            i.data()
          );
          for (let index = 0; index < retailPackagesWithTermsData.length; index++) {
            const element = retailPackagesWithTermsData[index];
            let newTerms = this.item;
            await portalsApp
              .firestore()
              .collection("retailPackages")
              .doc(element.id)
              .update({ terms: newTerms });
          }

          this.loading = false;
          this.dialog = false;
          this.item = "";
          // success snackbar
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Item updated successfully",
            timeout: 5000,
          });
        }
      } else {
        if (!this.$refs.form.validate()) return;
        // adds new doc to termsAndConditions collection
        this.loading = true;
        await mainApp
          .firestore()
          .collection("termsAndConditions")
          .add(this.item);
        this.loading = false;
        this.dialog = false;
        this.item = "";
        this.$refs.form.reset();
        // success snackbar
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Item added successfully",
          timeout: 5000,
        });
      }
    },
  },
};
</script>
